"use client"

import React from "react"
import PricePlanCard from "@/application/prices/components/PricePlanCard"
import { useCheckout } from "@/application/checkout/components/CheckoutProvider"
import { observer } from "mobx-react"

const PricePlanList = () => {
  const { checkoutStore } = useCheckout()

  return (
    <div className="price-plans">
      {checkoutStore.pricePlans.map((pricePlan, i) => (
        <PricePlanCard
          key={i}
          pricePlan={pricePlan}
          features={checkoutStore.features}
          onProcessSubscription={checkoutStore.controller.onProcessSubscription}
        />
      ))}
    </div>
  )
}

export default observer(PricePlanList)
