import FeatureCard from "@/application/landing/components/main-page/content/features/FeatureCard"

import CPAPic from "@/assets/images/CPA-feature-card.png"
import ROASPic from "@/assets/images/ROAS.png"
import BRCPic from "@/assets/images/BRC.png"

const FeaturesSectionContent = () => {
  return (
    <div className="features-content">
      <FeatureCard
        title="CPA Reports"
        image={CPAPic}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
      <FeatureCard
        title="ROAS Report"
        image={ROASPic}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
      <FeatureCard
        title="Business Report Card"
        image={BRCPic}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
    </div>
  )
}

export default FeaturesSectionContent
