import Link from "next/link"
import React from "react"
import classnames from "classnames"
import { CalendlyLink } from "@/config/const"

interface IProps {
  className?: string
}

const ScheduleDemoButton = ({ className }: IProps) => {
  return (
    <div
      className={classnames("w-full flex justify-center", className, {
        "mt-16": !className || (className && className.indexOf("mt-") < 0),
      })}
    >
      <Link
        className="button outlined schedule-demo"
        href={CalendlyLink}
        target="_blank"
      >
        SCHEDULE A DEMO NOW
      </Link>
    </div>
  )
}

export default ScheduleDemoButton
