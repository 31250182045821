"use client"

import { Button } from "antd"
import dashboardPreviewPic from "@/assets/images/dashboard-preview.png"
import Image from "next/image"
import React, { ReactNode } from "react"
import { useRouter } from "next-nprogress-bar"
import { Routes } from "@/services/routes"

type Router = ReturnType<typeof useRouter>

interface IProps {
  showAgitation?: boolean
  title?: string | ReactNode
  description?: string | ReactNode
  GetStartedComponent?: React.FC<{ router?: Router }>
}

const DefaultTitle = <h1>Automated and Insightful</h1>

const DefaultDescription =
  "Automated reporting tools can help businesses overcome these challenges\n" +
  "        by automating the reporting process."

const GetStarted: React.FC<{ router?: Router }> = ({ router }) => {
  return (
    <Button
      type="primary"
      className="button"
      onClick={() => {
        router?.push(Routes.office.user.login)
      }}
    >
      Get Started
    </Button>
  )
}

const AboutBlock = ({
  showAgitation = true,
  title = DefaultTitle,
  description = DefaultDescription,
  GetStartedComponent = GetStarted,
}: IProps) => {
  const router = useRouter()

  return (
    <div className="about-block">
      {showAgitation && (
        <div className="agitation" key="agitation">
          Get Smarter Reporting with Automation
        </div>
      )}
      <div className="title" key="title">
        {title}
      </div>
      <div className="description" key="description">
        {description}
      </div>
      <div className="get-started" key="get-started">
        <GetStartedComponent router={router} />
      </div>
      <div className="dashboard-preview">
        <Image src={dashboardPreviewPic} alt="Dashboard preview" />
      </div>
    </div>
  )
}

export default AboutBlock
