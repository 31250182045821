"use client"

import { PricePlan, PricePlanFeature } from "@/application/entities/prices"
import { Button } from "antd"
import { useCallback } from "react"
import CheckedIcon from "@/ui/icons/CheckedIcon"
import UncheckedIcon from "@/ui/icons/UncheckedIcon"
import classnames from "classnames"

interface IProps {
  pricePlan: PricePlan
  features: PricePlanFeature[]
  onProcessSubscription: (pricePlanId: PricePlan["id"]) => Promise<void>
}

type PricePlanFeatureIfPinned = {
  pinned: boolean
} & PricePlanFeature

const PricePlanCard = ({
  pricePlan,
  features,
  onProcessSubscription,
}: IProps) => {
  const getFeatures = useCallback(() => {
    return features.reduce(
      (
        priceFeatures: PricePlanFeatureIfPinned[],
        iterFeature: PricePlanFeature,
      ) => {
        priceFeatures.push({
          ...iterFeature,
          pinned: pricePlan.features.includes(iterFeature.id),
        })
        return priceFeatures
      },
      [],
    )
  }, [])

  return (
    <div
      className={classnames("price-plan", {
        highlight: pricePlan.is_highlight,
      })}
    >
      <div className="price-plan-title-wrapper">
        <div className="price-plan-title">{pricePlan.name}</div>
        <div className="price-plan-description">{pricePlan.description}</div>
      </div>
      <div className="price-plan-amount">
        <span className="price-plan-amount__price">
          ${pricePlan.price_month}
        </span>{" "}
        / Month
      </div>
      <Button
        className="button outlined button__checkout"
        onClick={() => onProcessSubscription(pricePlan.id)}
      >
        Get Started Now
      </Button>
      <div className="price-plan-features">
        {getFeatures().map((feature, i) => (
          <div className="price-plan-feature" key={i}>
            {(feature.pinned && (
              <CheckedIcon
                background={(pricePlan.is_highlight && "white") || "#2563EB"}
                opacity={(pricePlan.is_highlight && 1) || 0.08}
              />
            )) || <UncheckedIcon />}
            {feature.name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PricePlanCard
