import { CheckoutStore } from "@/application/checkout/store"
import { action, makeObservable } from "mobx"
import { PricePlan } from "@/application/entities/prices"
import { client } from "@/services/api"
import { Routes } from "@/services/routes"
import { NewCheckoutData } from "@/application/entities/checkout"

export class CheckoutController {
  store: CheckoutStore

  static make(store: CheckoutStore) {
    return new CheckoutController(store)
  }

  constructor(store: CheckoutStore) {
    makeObservable(this)

    this.store = store
  }

  @action.bound
  async onProcessSubscription(pricePlanId: PricePlan["id"]) {
    if (!this.store.userId) {
      window.location.href = "/office/register"
    }
    const response = await client.post<NewCheckoutData>(
      Routes.api.checkout.process,
      {
        user_id: this.store.userId,
        price_plan_id: pricePlanId,
        mode: this.store.mode,
      },
    )

    if (response.data.redirect_url) {
      window.location.href = response.data.redirect_url
    }
  }
}
