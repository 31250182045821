import { action, makeObservable, observable } from "mobx"
import { User } from "@/application/entities/user"
import { PricePlan, PricePlanFeature } from "@/application/entities/prices"
import { CheckoutController } from "@/application/checkout/controller"

type ActivateData = {
  mode: "select" | "register" | "landing"
  user_id?: User["id"]
  pricePlans: PricePlan[]
  features: PricePlanFeature[]
}

export class CheckoutStore {
  controller: CheckoutController

  @observable
  userId?: User["id"]
  @observable
  pricePlans: PricePlan[] = []
  @observable
  features: PricePlanFeature[] = []
  @observable
  mode: "select" | "register" | "landing"

  static make() {
    return new CheckoutStore()
  }

  constructor() {
    makeObservable(this)

    this.controller = CheckoutController.make(this)
  }

  @action.bound
  activate({ user_id, pricePlans, features, mode }: ActivateData) {
    this.userId = user_id
    this.pricePlans = pricePlans
    this.features = features
    this.mode = mode
  }
}

export const checkoutStore = CheckoutStore.make()
