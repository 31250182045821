"use client"

import { User } from "@/application/entities/user"
import React from "react"
import CheckoutProvider from "@/application/checkout/components/CheckoutProvider"
import { PricePlan, PricePlanFeature } from "@/application/entities/prices"
import { observer } from "mobx-react"

interface IProps {
  mode: "select" | "register" | "landing"
  user_id?: User["id"]
  pricePlans: PricePlan[]
  features: PricePlanFeature[]
}

const CheckoutService = ({
  mode,
  pricePlans,
  features,
  user_id,
  children,
}: React.PropsWithChildren & IProps) => {
  return (
    <CheckoutProvider
      mode={mode}
      pricePlans={pricePlans}
      features={features}
      user_id={user_id}
    >
      {children}
    </CheckoutProvider>
  )
}

export default observer(CheckoutService)
