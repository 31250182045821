"use client"

import { PricePlan, PricePlanFeature } from "@/application/entities/prices"
import { User } from "@/application/entities/user"
import CheckoutService from "@/application/checkout/components/CheckoutService"
import React from "react"
import PricePlanList from "@/application/prices/components/PricePlanList"
import { observer } from "mobx-react"
import { useSession } from "next-auth/react"
import Loading from "@/ui/components/Loading"

interface IProps {
  mode?: "select" | "register" | "landing"
  pricePlans: PricePlan[]
  features: PricePlanFeature[]
  user_id?: User["id"]
}

const PricePlans = ({
  mode = "register",
  pricePlans,
  features,
  user_id,
}: React.PropsWithChildren & IProps) => {
  const { status, data: session } = useSession()

  if (status == "loading") return <Loading />

  if (!!session?.user && !session.error) {
    user_id = (session.user as User).id
  }

  if (mode == "landing" && user_id) {
    mode = "select"
  }

  return (
    <CheckoutService
      pricePlans={pricePlans}
      features={features}
      user_id={user_id}
      mode={mode}
    >
      <PricePlanList />
    </CheckoutService>
  )
}

export default observer(PricePlans)
