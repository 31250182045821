import Image from "next/image"
import { StaticImageData } from "next/dist/shared/lib/get-img-props"
import { Button } from "antd"
import React from "react"
import ArrowLinkIcon from "@/ui/icons/ArrowLinkIcon"
import { Routes } from "@/services/routes"

interface IProps {
  title: string
  image: StaticImageData
  description: string
}

const FeatureCard = ({ title, image, description }: IProps) => {
  return (
    <div className="feature-card">
      <div className="feature-image">
        <Image
          src={image}
          alt=""
          width={image.width}
          height={image.height}
        ></Image>
      </div>
      <div className="feature-title text-center text-zinc-900 text-xl font-bold">
        {title}
      </div>
      <div className="feature-description flex justify-center text-center text-zinc-700 text-sm font-normal">
        <span className="max-w-[306px] block">{description}</span>
      </div>
      <div className="feature-button flex justify-center">
        <Button
          type="primary"
          className="button"
          href={Routes.landing.features.page}
        >
          <span>See Example</span>
          <ArrowLinkIcon />
        </Button>
      </div>
    </div>
  )
}

export default FeatureCard
