import React from "react"
import FeaturesSectionTitle from "@/application/landing/components/main-page/content/features/FeaturesSectionTitle"
import FeaturesSectionContent from "@/application/landing/components/main-page/content/features/FeaturesSectionContent"

interface ICompound {
  Title: typeof FeaturesSectionTitle
  Content: typeof FeaturesSectionContent
}

const FeaturesSection: React.FC<React.PropsWithChildren> & ICompound = ({
  children,
}) => {
  return <div className="section features-section">{children}</div>
}

FeaturesSection.Title = FeaturesSectionTitle
FeaturesSection.Content = FeaturesSectionContent

export default FeaturesSection
