"use client"

import { Routes } from "@/services/routes"
import { Button } from "antd"
import { useRouter } from "next-nprogress-bar"

const FeaturesSectionTitle = () => {
  const router = useRouter()

  const goPage = () => {
    router.push(Routes.landing.features.page)
  }

  return (
    <div className="section-title features-title">
      <Button
        className="features-title__button button outlined"
        onClick={goPage}
      >
        Our Features
      </Button>
      <h2 className="section-title__text features-title__text">
        Gain actionable insights from your{" "}
        <span className="marketing">marketing</span> data
      </h2>
    </div>
  )
}

export default FeaturesSectionTitle
