const UncheckedIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#F7F8F9" />
      <path
        d="M20.243 20.2426C20.5034 19.9823 20.5034 19.5602 20.243 19.2998L16.9437 16.0005L20.244 12.7002C20.5043 12.4398 20.5043 12.0177 20.244 11.7574C19.9836 11.497 19.5615 11.497 19.3011 11.7574L16.0009 15.0577L12.7006 11.7574C12.4402 11.497 12.0181 11.497 11.7578 11.7574C11.4974 12.0177 11.4974 12.4398 11.7578 12.7002L15.058 16.0005L11.7587 19.2998C11.4983 19.5602 11.4983 19.9823 11.7587 20.2426C12.019 20.503 12.4411 20.503 12.7015 20.2426L16.0009 16.9433L19.3002 20.2426C19.5606 20.503 19.9827 20.503 20.243 20.2426Z"
        fill="#191D23"
      />
    </svg>
  )
}

export default UncheckedIcon
