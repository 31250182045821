const ArrowLinkIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_4243)">
        <path
          d="M14.1654 6.81787L5.83203 15.1512"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 6.81787H14.168V14.3179"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4243">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.984619)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowLinkIcon
