"use client"

import React, { useContext, useEffect } from "react"
import { checkoutStore } from "@/application/checkout/store"
import { observer, useLocalObservable } from "mobx-react"
import { User } from "@/application/entities/user"
import { PricePlan, PricePlanFeature } from "@/application/entities/prices"

const stores = {
  checkoutStore,
}

const CheckoutContext = React.createContext(stores)

interface IProps {
  mode: "select" | "register" | "landing"
  user_id?: User["id"]
  pricePlans: PricePlan[]
  features: PricePlanFeature[]
}

const CheckoutProvider = ({
  children,
  mode,
  pricePlans,
  features,
  user_id,
}: React.PropsWithChildren & IProps) => {
  const _stores = useLocalObservable(() => stores)

  useEffect(() => {
    stores.checkoutStore.activate({ user_id, pricePlans, features, mode })
  }, [pricePlans, features])

  return (
    <CheckoutContext.Provider value={_stores}>
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => {
  return useContext(CheckoutContext)
}

export default observer(CheckoutProvider)
