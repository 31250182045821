import { Flex, Spin } from "antd"

const Loading = () => {
  return (
    <Flex
      align="center"
      justify="center"
      gap="middle"
      className="w-full h-full min-w-20 min-h-20"
    >
      <Spin size="large" />
    </Flex>
  )
}

export default Loading
