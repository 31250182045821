const CheckedIcon = ({
  background = "#2563EB",
  color = "#2563EB",
  opacity = 0.08,
}: {
  background?: "white" | "#2563EB"
  color?: "white" | "#2563EB"
  opacity?: number
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill={background}
        fillOpacity={opacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8162 12.207C22.0701 12.4737 22.0597 12.8957 21.793 13.1495L14.0893 20.4829C13.9577 20.6081 13.7808 20.6742 13.5993 20.666C13.4179 20.6577 13.2477 20.5758 13.128 20.4391L10.1651 17.0545C9.92254 16.7775 9.95052 16.3563 10.2276 16.1138C10.5046 15.8713 10.9258 15.8992 11.1683 16.1763L13.6734 19.0379L20.8737 12.1838C21.1404 11.9299 21.5624 11.9403 21.8162 12.207Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckedIcon
